<template>
  <div class="resultat" ref="resultat" tabindex="-1" @keyup.space="avancer">
    <transition name="fade">
      <div class="bravo" v-if="afficher">
        <h1>Bravo !</h1>
        <p>Vous avez gagné</p>
      </div>
    </transition>

    <transition name="warp">
      <div class="boite" v-if="lot">
        <div class="lot" v-html="lot.nom"></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Resultat",
  data() {
    return {
      afficher: false,
      lot: false,
    };
  },
  mounted() {
    this.$refs.resultat.focus();
    setTimeout(() => {
      this.afficher = true;
      ion.sound.play("tada");
    }, 500);
    // window.addEventListener("keyup", this.avancer);

    this.$store.dispatch("tirerLot").then((lot) => {
      setTimeout(() => {
        this.lot = lot;
        ion.sound.play("cheer");
      }, 2000);
    });
  },
  methods: {
    avancer() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
.resultat {
  position: relative;
  .bravo {
    text-align: center;
    position: absolute;
    top: 5%;
    left: 50%;
    color: white;
    text-transform: uppercase;
    h1 {
      font-weight: bold;
      font-size: 11vh;
    }
    p {
      font-size: 5vh;
    }
    transform: translateX(-50%);
  }
  .boite {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70%;
    .lot {
      position: absolute;
      top: 50%;
      left: 50%;
      background: #f58420;
      color: white;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 6vh;
      line-height: 7vh;
      max-width: 50%;
      padding: 2%;
      transform: rotate(-5deg) translateX(-50%) translateY(-50%);
    }
  }
}
</style>